
.explore{
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    background-color: rgb(255, 255, 255);
    height: 715px;
    background-size: cover;
    margin-bottom: 4%;
}

.exploreDestinations{
    width:87%;
    height:660px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(245, 245, 245); 
    border-radius: 40px;
    margin-top: 4%;
}

.exploreDestinations h2{
    margin-top: 20px;
    font-size: 35px;
    font-weight: 500;
}

.exploreDestinations p{
    margin-top: -35px;
}

.destinations{
    height:450px;
    width: 99%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.button{
    margin: 10px;
    height:60px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    border-radius: 8px;
    color:white; 
}

.button:hover{
    cursor: pointer;
}

.disabledbutton{
    margin: 10px;
    height:60px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: silver;
    border-radius: 8px;
    color:white;
}

.disabledbutton{
    cursor:default;
}

.left,.right{
    padding-top: 50px;
    font-size: 40px;
    font-weight: 400;
}

.destinationsData{
    position: relative;
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 78%;
    height: 450px;
    overflow:hidden;
}

.destination{
    position: absolute;
    margin: 1%;
    height:420px;
    width: 31%;
    background-color: white;
    border: 2px solid black;
    border-radius: 20px;
    overflow: hidden;
}

.imageSectionofdestination{
    width: 100%;
    height: 55%;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
}

.nameOfTheDestination{
    margin-left: 10px;
    margin-top: 10px;
}

.destination h3{

    font-size: 30px;
    font-weight:500;
}
.destination p{

    padding-left: 13px;
    margin-top: -25px;
    margin-bottom: 20px;
}

.corePoints{
    display: flex;
}

.corePoints div{
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 2px;
    padding-bottom: 4px;
    background-color: rgb(218, 217, 217);
    margin-left: 13px;
    border-radius: 6px;
}

.exploreButton{
    margin-top: 17px;
    margin-left: 31%;
    background-color: black;
    color:white;
    font-size: 17px;
    border: none;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 8px;
}

.exploreButton:hover{
    cursor: pointer;
}

#firstDestination .imageSectionofdestination{
    background-image: url('/public/AITajMahal.jpeg');
    background-size: cover;
}

#secondDestination .imageSectionofdestination{
    background-image: url('/public/eiffelTower.jpeg');
    background-size: cover;
}

#thirdDestination .imageSectionofdestination{
    background-image: url('/public/AIGoldenPavilion.jpeg');
    background-size: cover;
}

#fourthDestination .imageSectionofdestination{
    background-image: url('/public/SOL.jpeg');
    background-size: cover;
}

#fifthDestination .imageSectionofdestination{
    background-image: url('/public/AmberFort.jpeg');
    background-size: cover;
}

#firstDestination{
    margin-left: 1%;
}

#secondDestination{
    margin-left: 34%;
}

#thirdDestination{
    margin-left: 67%;
}

#fourthDestination{
    margin-left: 100%;
}

#fifthDestination{
    margin-left: 133%;
}


.exploreMore{
    margin-top: 20px;
    font-size: 22px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 8px;
    padding-right: 8px;
    background-color: white;
    border: none;
    border: 1px solid black;
    font-weight: 100;
    border-radius: 8px;
}

.exploreMore:hover{
    cursor: pointer;
}

.exploreMorePopupWindow{
    z-index: 100;
    position: fixed;
    align-items: center;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.585);
    overflow-y: auto; 
}

.exploreMorePopup{
    width: 40%;
    height: 55%;
    background-color: white;
    border: 2px solid black;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.exploreMorePopup h2{
    font-size: 35px;
    margin-top: 40px;
    margin-bottom: 30px;
    font-weight: 500;
    color: #000000;
  
  }

.inputCountry{
    width: 70%;
    padding: 10px;
    border: none;
    border-radius: 5px;
}

.closeExploreMoreOption{
    font-size:28px;
    font-weight: bold;
    float:right;
    margin-left:94%;
    color: #626161;
  }
  
  .closeExploreMoreOption:hover{
    cursor: pointer;
    color:#000000;
  }
  
  .exploreCountryAndStateOption{
    margin-left: 15%;
    margin-top: 30px;
    font-size: 20px;
    width: 75%;
    background-color: rgb(0, 0, 0);
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 10px;
    border: 1px solid black
  }

  @media (max-width:1310px){
    .destination{
        position: absolute;
        margin: 1%;
        height:420px;
        width: 31%;
        background-color: white;
        border: 2px solid black;
        border-radius: 20px;
        overflow: hidden;
    }    
    @media (max-width:1310px){
        .destination{
            width: 47%;
        }
        #firstDestination{
            margin-left: 1%;
        }
        
        #secondDestination{
            margin-left: 51%;
        }
        
        #thirdDestination{
            margin-left: 101%;
        }
        
        #fourthDestination{
            margin-left: 151%;
        }
        
        #fifthDestination{
            margin-left: 201%;
        }

        .exploreMorePopup{
            width: 50%;
            height: 60%;
            background-color: white;
            border: 2px solid black;
            border-radius: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .exploreMorePopup h2{
            font-size: 27px;
        }
        .inputCountry{
            width: 70%;
            padding: 10px;
            border: none;
            border-radius: 5px;
        }
        .exploreCountryAndStateOption{
            margin-left: 15%;
            margin-top: 30px;
            font-size: 20px;
            width: 75%;
            background-color: rgb(0, 0, 0);
            color: white;
            border: none;
            border-radius: 5px;
            padding: 10px 10px;
            border: 1px solid black
          }

        @media (max-width:800px){
            .corePoints{
                display: none;
            }
            .destination{
                height: 350px;
            }
            .destination h3{
                font-size: 25px;
            }
            .destination p{
                padding-left: 13px;
            }
            .exploreButton{
                margin-top: 5px;
            }
            .exploreMorePopup{
                width: 65%;
                height: 60%;
                background-color: white;
                border: 2px solid black;
                border-radius: 20px;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            .exploreMorePopup h2{
                padding-left: -15px;
                font-size: 25px;
            }
           
        }
        @media (max-width:600px){
            .destination{
                height: 350px;
                width: 98%;
            }
            #firstDestination{
                margin-left: 1%;
            }
            
            #secondDestination{
                margin-left: 104.7%;
            }
            
            #thirdDestination{
                margin-left: 208.4%;
            }
            
            #fourthDestination{
                margin-left: 312.1%;
            }
            
            #fifthDestination{
                margin-left: 415.8%;
            }
            .exploreMorePopup{
                width: 75%;
                height: 60%;
                background-color: white;
                border: 2px solid black;
                border-radius: 20px;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            @media (max-width:500px) {
                .exploreMorePopup{
                    width: 85%;
                    height: 60%;
                    background-color: white;
                    border: 2px solid black;
                    border-radius: 20px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
            }
            @media(max-width:436px){
                .destinations{
                    margin-top: -20px;
                    
                }
                .destination{
                    height: 300px;
                }
                .exploreDestinations h2{
                    font-size: 30px;
                    font-weight: 500;
                    padding-top: 10px;
                }
                .exploreDestinations p{
                    font-size: 13px;
                }
                
                .destination h3{
                    font-size: 23px;
                }
                .destination p{
                    padding-left: 13px;
                }
                .exploreButton{
                    margin-top: -10px;
                }
                .exploreDestinations{
                    background-color: aqua;
                    width:87%;
                    height:600px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    background-color: rgb(245, 245, 245); 
                    border-radius: 40px;
                    margin-top: 4%;
                    
                }
                .exploreMore{
                    z-index: 1;
                    margin-top: -30px;
                    
                }
                #firstDestination{
                    margin-left: 0%;
                }
                
                #secondDestination{
                    margin-left: 104.39%;
                }
                
                #thirdDestination{
                    margin-left: 208.78%;
                }
                
                #fourthDestination{
                    margin-left: 313.17%;
                }
                
                #fifthDestination{
                    margin-left: 417.56%;
                }

                .exploreMorePopup{
                    width: 95%;
                    height: 60%;
                    background-color: white;
                    border: 2px solid black;
                    border-radius: 20px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }

                .exploreMorePopup h2{
                    margin-left: -0px;
                    font-size: 22px;
                }
                .inputCountry{
                    width: 80%;
                    padding: 10px;
                    border: none;
                    border-radius: 5px;
                }
                .exploreCountryAndStateOption{
                    margin-left: 15%;
                    margin-top: 30px;
                    font-size: 20px;
                    width: 75%;
                    background-color: rgb(0, 0, 0);
                    color: white;
                    border: none;
                    border-radius: 5px;
                    padding: 10px 10px;
                    border: 1px solid black
                  }
                
                @media(max-width:353px){
                    .exploreDestinations{
                        width: 100%;
                    }
                    
                    .destination{
                        height: 300px;
                    }
                    .exploreDestinations h2{
                        font-size: 30px;
                        font-weight: 500;
                    }
                    .exploreDestinations p{
                        font-size: 13px;
                    }
                    
                    .destination h3{
                        font-size: 25px;
                    }
                    .destination p{
                        padding-left: 13px;
                    }
                    .exploreButton{
                        margin-left: 25%;
                        margin-top: -20px;
                    }
                    .exploreMorePopup h2{
                        margin-left:0px;
                        font-size: 22px;
                    }
                }
            }
        }
    }
  }