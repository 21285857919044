#earnings-for-tour-guide{
    margin-left:270px;
    height: 100vh;
}

@media only screen and (max-width: 1036px){
    #earnings-for-tour-guide{
        margin-left:0px;
        height: 100vh;
    }
    
}