#subscription-popUp-background{
    position: absolute;
    z-index: 1000;
    top: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.521);
}

#subscription-pop-up{
    width: 70%;
    height: 90%;
    background-color: rgb(0, 0, 0);
    border: 2px solid black;
    border-radius: 20px;
}

#close-subscription-pop-up-bar{
    margin-top: 10px;
    margin-right: 10px;
    width: 45px;
    height: 45px;
    background-color: rgba(255, 255, 255, 0.559);
    float: right;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

#close-subscription-popUp{
    font-size: 30px;
    color: black;
}

#close-subscription-pop-up-bar:hover{
    cursor: pointer;
    background-color: azure;
}


center{
    margin-top: 100px;
    margin-bottom: -130px;
}

#subscription-plan-main-heading{
    color: white;
    font-size: 40px;
}

#subscription-types{
    margin-top: 160px;
    width: 100%;
    height: 430px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
}

#subscription-types aside{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.subscription-plan-details{
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 300px;
    background-color: rgba(255, 255, 255, 0.86);
    border: 2px solid black;
    border-radius: 10px;
}

.subscription-plan-details:hover{
    background-color: white;
}

.subscription-plan-heading{
    font-size: 25px;
    margin-top: 0px;
    margin-bottom: -20px;
}

.subscription-plan-details ul{
    margin-bottom: 50px;
}

.purchase-subscription-button{
    background-color: #000000;
    color: white;
    padding: 8px 10px;
    border-radius: 10px;
}
  
.purchase-subscription-button:hover{
    cursor: pointer;
}

@media (max-width:1000px) {
    #subscription-pop-up{
        width: 90%;
        height: 90%;
        background-color: rgb(0, 0, 0);
        border: 2px solid black;
        border-radius: 20px;
    }
}

@media (max-width: 770px) {
    #subscription-pop-up{
        width: 99%;
    }
    .subscription-plan-details{
        width: 100%;
    }
    #subscription-types{
        margin-left: 2%;
        margin-right: 10px;
        width: 96%;
    }
    
    .subscription-plan-heading{
        font-size: 20px;
    }
}

@media (max-width:500px) {
    #subscription-pop-up{
        height: 80%;
    }
    .subscription-plan-details{
        margin-top: -40px;
        height: 250px;
    }
    .subscription-plan-details h1{
        font-size: 16px;
    }
    
    .subscription-plan-heading{
        margin-bottom: -15px;
        font-size: 20px;
    }

    .subscription-plan-details ul{
        margin-left: -20%;
    }

    .subscription-plan-details ul li{
        font-size: 14px;
    }
}

@media (max-width:420px) {
    #subscription-pop-up{
        height: 80%;
    }
    .subscription-plan-details{
        margin-top: -40px;
        height: 250px;
    }
    .subscription-plan-details h1{
        font-size: 14px;
    }

    .subscription-plan-details ul{
        margin-left: -20%;
    }

    .subscription-plan-details ul li{
        font-size: 12px;
    }
}

@media (max-width:375px) {
    #subscription-pop-up{
        height: 70%;
    }
    center{
        margin-top: 100px;
        margin-bottom: -170px;
    }
    .subscription-plan-details{
        margin-top: -40px;
        height: 230px;
    }
    .subscription-plan-details h1{
        font-size: 12px;
    }

    #subscription-plan-main-heading{
        color: white;
        font-size: 35px;
    }
    .subscription-plan-details ul{
        margin-left: -20%;
    }

    .subscription-plan-details ul li{
        font-size: 10px;
    }
    .purchase-subscription-button{
        font-size: 10px;
    }
}