.InformationAboutAudioBook{
    margin: 0;
    padding: 0;
    display: flex;
    background-color: rgb(255, 255, 255);
    height: 715px;
    background-size: cover;
    
}

.textSection{
    width: 730px;
    
}

.textSection h2{
    margin-top: 150px;
    margin-left: 13%;
    font-size: 55px;
    font-weight: 500;
}

.textSection p{
    margin-left: 13%;
    font-size: 19px;
}

.listView{
    padding-top: 3px;
}

.listView ul{
    list-style-type:none;
    margin-left: 100px;
}

.listView ul li{
    text-indent: -30%;
    display: flex;
    align-items: center;
}

.imageSection{
    width: 450px;
}

.imagePart{
    margin-top: 130px;
    margin-left: 100px;
    background-image: url('/public/AIpersonListeningAudioBook.jpeg');
    background-size: cover;
    width: 500px;
    height: 550px;
    border-radius: 25px;
}

@media only screen and (max-width: 1400px){
    .textSection{
        width: 700px;
    }
    .textSection h2{
        margin-left: 13%;
        font-size: 55px;
        font-weight: 500;
    }
    
    .textSection p{
        margin-left: 13%;
        font-size: 19px;
    }
    .listView{
        padding-top: 3px;
    }
    
    .listView ul{
        list-style-type:decimal-leading-zero;
        margin-left: 100px;
    }
    
    .listView ul li{
        text-indent: -9%;
    }
    
    .imageSection{
        width: 450px;
    }
    
    .imagePart{
        margin-top: 130px;
        margin-left: 100px;
        background-image: url('/public/AIpersonListeningAudioBook.jpeg');
        background-size: cover;
        width: 500px;
        height: 550px;
        border-radius: 25px;
    }
    @media only screen and (max-width: 1330px){
        .textSection{
            width: 650px;
        }
        .textSection h2{
            margin-left: 13%;
            font-size: 50px;
            font-weight: 500;
        }
        
        .textSection p{
            margin-left: 14%;
            font-size: 16px;
        }
        @media only screen and (max-width: 1270px){
            .textSection{
                width: 600px;
            }
            .textSection h2{
                margin-left: 13%;
                font-size: 40px;
                font-weight: 500;
            }
            
            .textSection p{
                margin-left: 14%;
                font-size: 15px;
            } 
            .imageSection{
                width: 450px;
            } 
            .imagePart{
                margin-top: 130px;
                margin-left: 50px;
                background-image: url('/public/AIpersonListeningAudioBook.jpeg');
                background-size: cover;
                width: 500px;
                height: 550px;
                border-radius: 25px;
            }
            @media only screen and (max-width: 1165px){
                .textSection{
                    width: 550px;
                }
                .textSection h2{
                    margin-left: 13%;
                    font-size: 40px;
                    font-weight: 500;
                }
                
                .textSection p{
                    margin-left: 14%;
                    font-size: 15px;
                } 
                .imageSection{
                    width: 450px;
                } 
                .imagePart{
                    margin-top: 130px;
                    margin-left: 50px;
                    background-image: url('/public/AIpersonListeningAudioBook.jpeg');
                    background-size: cover;
                    width: 450px;
                    height: 500px;
                    border-radius: 25px;
                }
                @media only screen and (max-width: 1076px){
                    .textSection{
                        background-size: cover;
                        margin-left: 200px;
                        z-index: 2;
                        width: 550px;
                    }
                    .textSection h2{
                        margin-left: 13%;
                        font-size: 40px;
                        font-weight: 500;
                    }
                    
                    .textSection p{
                        margin-left: 14%;
                        font-size: 15px;
                    } 
                    .imageSection{
                        margin-left: -540px;
                        width: 470px;
                    } 
                    .imageCovering{
                        height: 100%;
                        width: 100%;
                        background-color: rgba(255, 255, 255, 0.696);
                    }
                    .imagePart{
                        margin-top: 100px;
                        margin-left: 50px;
                        background-image: url('/public/AIpersonListeningAudioBook.jpeg');
                        background-size: cover;
                        width: 500px;
                        height: 500px;
                        border-radius: 25px;
                    }
                    @media only screen and (max-width: 830px){
                        .textSection{
                            margin-left: 5%;
                        }
                        @media only screen and (max-width: 585px){
                            .textSection{
                                width: 400px;
                                margin-left: 10%;
                            }
                            .textSection h2{
                                margin-left: 10%;
                                font-size: 40px;
                                font-weight: 500;
                            }
                            
                            .textSection p{
                                margin-left: 10%;
                                font-size: 15px;
                            }
                            .listView ul{
                                list-style-type:decimal-leading-zero;
                                margin-left: 50px;
                            }
                            
                            .listView ul li{
                                text-indent: -9%;
                            }
                            .imagePart{
                                margin-top: 100px;
                                margin-left: 120px;
                                background-image: url('/public/AIpersonListeningAudioBook.jpeg');
                                background-size: cover;
                                width: 450px;
                                height: 500px;
                                border-radius: 25px;
                            }
                            @media only screen and (max-width: 496px){
                                .textSection{
                                    width: 100%;
                                    margin-left: 0%;
                                    margin-top: -100px;
                                }
                                .textSection h2{
                                    margin-top: 55%;
                                    margin-left: 3%;
                                    font-size: 40px;
                                    font-weight: 500;
                                }
                                
                                .textSection p{
                                    margin-left: 3%;
                                    font-size: 15px;
                                }
                                .listView ul{
                                    list-style-type:decimal-leading-zero;
                                    margin-left: 10px;
                                }
                                
                                .listView ul li{
                                    text-indent: 0%;
                                }
                                .imagePart{
                                    margin-top: 100px;
                                    margin-left: 0px;
                                    background-image: url('/public/AIpersonListeningAudioBook.jpeg');
                                    background-size: cover;
                                    width: 540px;
                                    height: 540px;
                                    border-radius: 0px;
                                }
                            }
                            @media only screen and (max-width :430px){
                                .textSection{
                                    width: 100%;
                                    margin-left: 0%;
                                    margin-top: -60px;
                                }
                                .textSection h2{
                                    margin-top: 55%;
                                    margin-left: 3%;
                                    font-size: 35px;
                                    font-weight: 500;
                                }
                                
                                .textSection p{
                                    margin-left: 3%;
                                    font-size: 15px;
                                }
                                .listView{
                                    margin-left: -20px;
                                }
                                .imagePart{
                                    margin-top: 100px;
                                    margin-left: 0px;
                                    background-image: url('/public/AIpersonListeningAudioBook.jpeg');
                                    background-size: cover;
                                    width: 540px;
                                    height: 540px;
                                    border-radius: 0px;
                                }
                                @media only screen and (max-width:380px){
                                    .textSection{
                                        margin-top: -40px;
                                    }
                                    @media only screen and (max-width:330px){
                                        .textSection{
                                            margin-top: -30px;
                                        }
                                        .textSection h2{
                                            font-size: 30px;
                                        }
                                        .imagePart{
                                            margin-top: 100px;
                                            margin-left: 0px;
                                            background-image: url('/public/AIpersonListeningAudioBook.jpeg');
                                            background-size: cover;
                                            width: 540px;
                                            height: 495px;
                                            border-radius: 0px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

    }
}