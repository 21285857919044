
.AboutUsPage {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.AboutUs {
  width: 80%;
  max-width: 800px;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  position: relative;
}

.closeAboutUs {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
  color: #333;
}

#headingOfAboutUs {
  font-size: 24px;
  color: #2c3e50;
  margin-bottom: 10px;
}

.subHeading{
  font-size: 24px;
  color: #2c3e50;
  margin-bottom: 10px;
}

.normalTextAboutUs {
  font-size: 16px;
  color: #7f8c8d;
  line-height: 1.6;
}

.aboutUsUl {
  padding-left: 20px;
}

.aboutUsLi {
  margin-bottom: 10px;
}

.subSubHead {
  font-size: 18px;
  color: #2c3e50;
  margin-bottom: 5px;
}

.liTextAboutUs{
  font-size: 16px;
  color: #7f8c8d;
  line-height: 1.6;
}
