.notification-panel {
    width: 300px;
    max-height: 1000px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    position: absolute;
    top: 62px;
    right: 80px;
    z-index: 1000;
}

.notification-panel h2 {
    margin: 0;
    padding: 10px;
    color: #fff;
    border-bottom: 1px solid #6a3333;
    font-size: 16px;
}

.notification-list {
    padding: 10px;
}

.notification-item {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
    transition: background-color 0.3s;
}

.notification-item:hover {
    background-color: #f1f1f1;
}

.notification-message {
    margin: 0;
    font-size: 14px;
    margin-bottom: 10px;
}

.notification-timestamp {
    display: block;
    font-size: 12px;
    color: #888;
}
