#tour-guide-review-page{
    margin-left:270px;
    height: 100vh;
}

.tour-guide-top-nav-bar{
    background-color: black;
    height: 70px;
}

.back-to-homescreen{
    margin-left: 40px;
    width:100px;
    border:none;
    font-size: 16px;
    padding:10px;
    background-color: white;
    border-radius: 10px;
    color: orangered;
}

#review-profile-page{
    padding-left: 100px;
    padding-top: 60px;
    height: 400px;
}

.profile-tour-guide{
    width:400px;
    height: 170px;
    display: grid;
    grid-template-columns: 1fr 2fr;
    padding: 20px;
    padding-bottom: 10px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

}

.profile-view-in-tour-guide{
    background-color: silver;
    height:100px;
    width: 100px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid black;
}

.total-trips{
    grid-column: 1 / -1;
}

@media only screen and (max-width: 1036px){
    #tour-guide-review-page{
        margin-left:0px;
        height: 100vh;
    }
    
}

#reviews-box{
    width: 83%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}



.review-card {
    border: 1px solid silver;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .rating-stars {
    font-size: 1.5em;
    color: gold;
  }

  @media only screen and (max-width: 917px){

    .profile-tour-guide{
        margin-left: -60px;
        width:400px;
        height: 170px;
        display: grid;
        grid-template-columns: 1fr 2fr;
        padding: 20px;
        padding-bottom: 10px;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    
    }
    
    .left-margin{
        margin-left: -60px;
    }
    #reviews-box{
        margin-left: -60px;
        width: 100%;
    }
  }
  
  @media only screen and (max-width: 768px){
    #reviews-box{
        width: 90%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
    }
  }

  @media only screen and (max-width: 500px){

    .profile-tour-guide{
        margin-left: -60px;
        width:300px;
        height: 140px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding: 20px;
        padding-bottom: 10px;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    
    }

    #reviews-box{
        width: 100%;
        display: grid;
        grid-template-columns: 1fr;
        gap: 10px;
    }
  }

  @media only screen and (max-width: 400px){
    .profile-tour-guide{
        margin-left: -85px;
        width:300px;
        height: 140px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding: 20px;
        padding-bottom: 10px;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    
    }
    .left-margin{
        margin-left: -85px;
        margin-right: 20px;
    }
    #reviews-box{
        width: 100%;
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
    }
    
    .review-card {
        border: 1px solid silver;
        padding: 10px;
        margin-bottom: 0px;
        border-radius: 5px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      }
  }
  