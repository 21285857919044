
.endingPage{
    position: relative;
    height: 565px;
    width: 100%;
    background-color: rgb(255, 255, 255);
    justify-content: center;
    align-items: center;
    
}

.ending{
    display: flex;
    height: 490px;
    width:100%;
}

.subscribeContent{
    margin-left: 3%;
    width: 40%;
    height:400px;
}

.copyright{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    position: absolute;
    bottom: 0;
    height: 40px;
    width: 100%;
    float:inline-end;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    margin-bottom: 10px;
    
    
}

.copyright span{
    font-size: 30px;
    padding-left: 10px;
    padding-right: 10px;
}

.follow{
    font-size: 35px;
    font-weight:700;
    padding-top: 20%;
    margin-left: 10%;
    display: flex;
    flex-direction: row;
    align-items: center;

}

.follow img{
    margin-right: 30px;
    height: 70px;
}

.follow-for-mobile-view{
    display: none;
    font-size: 35px;
    font-weight:700;
    
    margin-left: 10%;
}
.follow-for-mobile-view img{
    margin-right: 20px;
    height: 70px;
}

.textForNewsletter{
    padding-top: 10px;
    margin-left: 10%;
    width: 500px;
    padding-bottom: 10px;
    line-height: 1.7;
}

.subscribeForm{
    padding-top: 20px;
    margin-left: 10%;
    width: 510px;
    margin-bottom: 50px;
    
}

.subscribeForm p{
    font-size: 12px;
    margin-bottom: 100px;
}

.subscribeForm input[type="email"]{
    padding: 10px;
    font-size: 16px;
    width: 450px;
    height: 30px;
    border: 1px solid black;
    border-radius: 10px;
}

.subscribeForm input[type="submit"]{
    border: none;
    padding: 10px;
    padding-right: 20px;
    padding-left: 20px;
    font-size: 16px;
    margin-left: -118px;
    background-color: rgb(0, 0, 0);
    color: white;
    border: 1px solid black;
    border-radius: 10px;
}

.subscribeForm input[type="submit"]:hover{
    cursor: pointer;
}

.links{
    width: 50%;
    height:68vh;
    display: flex;
}

.linkforterms{
    margin-top: 20%;
    height: 200px;
    width: 300px;
    margin-left: 30%;
}

.linksfortermsHeading,
.contactUsHeading,
.followUsHeading{
    font-weight: 650;
}

.linkforterms a{
    text-decoration: none;
    color: black;
}

.followUs{
    margin-top: 20%;
    height: 300px;
    width: 180px;
    margin-left: 7%;
}

.contactUs{
    margin-top: 20%;
}

.followUs a{
    text-decoration: none;
    color: black;
}
.dividerLine{
    margin-left: 5%;
    margin-right: 6%;
    margin-top: 20px;
    height:1.8px;
    background-color: rgb(0, 0, 0);
}

.contactUs a{
    color: inherit;
    text-decoration: none;
    cursor: pointer;
  }

.termsAndConditionLink:hover{
    cursor: pointer;
}

.privacyPolicyLink:hover{
    cursor: pointer;
}

.disclaimerLink:hover{
    cursor: pointer;
}

.refundAndCancellationPolicy:hover{
    cursor:pointer;
}

.contactUsLink{
    cursor: pointer;
}

.followUs a:hover{
    cursor:pointer;
}

@media (max-width: 1280px){
    .subscribeContent{
        margin-left: 20%;
        margin-top: 10px;
        border: 2px solid black;
        width: 600px;
        height: 220px;
        margin-bottom: 3%;
        border-radius: 30px;
        background-color: rgb(246, 245, 244);
    }

    .subscribeForm{
        padding-top: 20px;
        margin-left: 10%;
        width: 90%;
        margin-bottom: 50px;
        
        
    }
    
    .subscribeForm p{
        font-size: 12px;
        margin-bottom: 100px;
    }
    
    .subscribeForm input[type="email"]{
        padding: 10px;
        font-size: 16px;
        width: 90%;
        height: 30px;
        border: 1px solid black;
        border-radius: 10px;
    }
    
    .subscribeForm input[type="submit"]{
        border: none;
        padding: 10px;
        padding-right: 20px;
        padding-left: 20px;
        font-size: 16px;
        margin-left: -118px;
        background-color: rgb(0, 0, 0);
        color: white;
        border: 1px solid black;
        border-radius: 10px;
    }

    .follow{
        display: none;
    }
    .follow-for-mobile-view{
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .endingPage{
        position: relative;
        height: 755px;
        width: 100%;
        background-color: rgb(255, 255, 255);
        justify-content: center;
        align-items: center;
    }
    .ending{
        display: flex;
        flex-direction: column;
        height: 680px;
        
    }
    .links{
        margin-top: -180px;
        margin-left: 60px;
        width: 90%;
        height: 400px;
        
    }
    .followUs{
        height: 250px;
        width: 180px;
        margin-left: 8%;
    }
    
    .contactUs{
        width: 330px;
        margin-top: 20%;
    }
    .follow{
        font-size: 35px;
        font-weight:700;
        margin-left: 10%;
        margin-top: -80px;
    }
    @media(max-width:900px){
        .subscribeContent{
            margin-left: 80px;
            margin-top: 10px;
            border: 2px solid black;
            width: 80%;
            height: 220px;
            margin-bottom: 3%;
            border-radius: 30px;
            background-color: rgb(246, 245, 244);
        }
        .links{
            margin-top: -150px;
            margin-left: 30px;
        }
        @media(max-width:640px){
            .subscribeContent{
                margin-left: 10px;
                margin-top: 10px;
                border: 2px solid black;
                width: 95%;
                height: 220px;
                margin-bottom: 3%;
                border-radius: 30px;
                background-color: rgb(246, 245, 244);
            }
            .textForNewsletter{
                width: 85%;
                margin-left: 20px;
            }
            .subscribeForm{
                width: 97%;
                margin-left: 15px;
            }
            .subscribeForm input[type="email"]{
                padding: 10px;
                font-size: 16px;
                width: 90%;
                height: 30px;
                border: 1px solid black;
                border-radius: 10px;
            }
        }
        @media (max-width:450px) {
            .subscribeContent{
                margin-left: 10px;
                margin-top: 10px;
                border: 2px solid black;
                width: 95%;
                height: 220px;
                margin-bottom: 3%;
                border-radius: 30px;
                background-color: rgb(246, 245, 244);
            }
            .textForNewsletter{
                width: 85%;
                margin-left: 20px;
            }
            .subscribeForm{
                width: 97%;
                margin-left: 15px;
            }
            .subscribeForm input[type="email"]{
                padding: 10px;
                font-size: 16px;
                width: 90%;
                height: 30px;
                border: 1px solid black;
                border-radius: 10px;
            }
            
            .links{
                height:400px;
                margin-top: -30px;
                margin-left: 0px;
                display: block;
                
            }
            .linkforterms{
                height: 200px;
                width: 200px;
                margin-left: 30px;
            }
            .followUs{
                margin-top: -220px;
                float:right;
                height: 200px;
                width: 80px;
                margin-left: 30px;
            }
            .contactUs{
                margin-top: -90px;
                width: 350px;
                margin-left: 30px;
            }
            .contactUs p{
                margin-bottom: -10px;
            }
            .copyright{
                font-size: 12px;
            }
            .copyright span{
                font-size: 18px;
            }
            
            @media (max-width:392px) {
                .subscribeContent{
                    margin-left: 5px;
                    margin-top: 10px;
                    border: 2px solid black;
                    width: 96%;
                    height: 220px;
                    margin-bottom: 3%;
                    border-radius: 30px;
                    background-color: rgb(246, 245, 244);
                }
                .textForNewsletter{
                    width: 95%;
                    margin-left: 20px;
                }
                .subscribeForm{
                    width: 95%;
                    margin-left: 5px;
                }
                .subscribeForm input[type="email"]{
                    padding: 10px;
                    font-size: 14px;
                    width: 94%;
                    height: 30px;
                    border: 1px solid black;
                    border-radius: 10px;
                }
                .subscribeForm input[type="submit"]{
                    border: none;
                    padding: 9.5px;
                    padding-right: 18px;
                    padding-left: 18px;
                    font-size: 14px;
                }
                .contactUs{
                    margin-top: -50px;
                    width: 300px;
                    margin-left: 30px;
                    background-color: rgb(255, 255, 255);
                }
                .linkforterms{
                    margin-top: 40%;
                }
                .linksforLinks {
                    display: flex;
                    justify-content: space-between; /* Distribute space between columns */
                    flex-wrap: wrap; /* Allow wrapping if needed */
                    width: 300px;
                  }
    
                .linkfortermsHeading {
                    width: 100%; /* Make the heading span the full width */
                    margin-bottom: 10px; /* Space below the heading */
                    font-weight: bold; /* Make the heading bold */
                    font-size: 12px;
                  }
                  
                  .linkfortermsOPtion {
                    display: flex;
                    flex-direction: column; /* Stack links vertically */
                    width: 50%; /* Make each column take up half the width */
                    box-sizing: border-box; /* Include padding and border in the element's width and height */
                  }
                  
                  .linkfortermsOPtion p {
                    margin: 5px 0; /* Space between links */
                    cursor: pointer;
                    white-space: nowrap; /* Prevent links from wrapping to the next line */
                  }
                  
                  /* Optional: Add styles for links */
                  .privacyPolicyLink, .termsAndConditionLink, .disclaimerLink, .contactUsLink, .refundAndCancellationPolicy {
                    font-size: 12px; /* Adjust font size */
                  }
                @media (max-width:332px) {
                    .subscribeContent{
                        margin-left: 10px;
                        margin-top: 10px;
                        border: 2px solid black;
                        width: 300px;
                        height: 220px;
                        margin-bottom: 3%;
                        border-radius: 30px;
                        background-color: rgb(246, 245, 244);
                    }
                    .textForNewsletter{
                        width: 280px;
                        margin-left: 20px;
                    }
                    .subscribeForm{
                        width: 300px;
                        margin-left: 15px;
                    }
                    .subscribeForm input[type="email"]{
                        padding: 10px;
                        font-size: 12.5px;
                        width: 260px;
                        height: 30px;
                        border: 1px solid black;
                        border-radius: 10px;
                    }
                    .subscribeForm input[type="submit"]{
                        border: none;
                        padding: 9.5px;
                        padding-right: 18px;
                        padding-left: 18px;
                        font-size: 12.5px;
                    }
                    .copyright{
                        margin-left: -10px;
                        font-size: 10px;
                    }
                    .contactUs{
                        width: 250px;
                        margin-left: 30px;
                        font-size: 15px;
                    }
                    .contactUs p{
                        margin-bottom: -10px;
                    }
                    .follow-for-mobile-view{
                        
                        font-size: 35px;
                        font-weight:700;
                        
                        margin-left: 3%;
                    }
                    .linkforterms{
                        margin-top: 30%;
                    }
                    .linksforLinks {
                        display: flex;
                        justify-content: space-between; /* Distribute space between columns */
                        flex-wrap: wrap; /* Allow wrapping if needed */
                        width: 300px;
                      }
        
                    .linkfortermsHeading {
                        width: 100%; /* Make the heading span the full width */
                        margin-bottom: 10px; /* Space below the heading */
                        font-weight: bold; /* Make the heading bold */
                        font-size: 12px;
                      }
                      
                      .linkfortermsOPtion {
                        display: flex;
                        flex-direction: column; /* Stack links vertically */
                        width: 50%; /* Make each column take up half the width */
                        box-sizing: border-box; /* Include padding and border in the element's width and height */
                      }
                      
                      .linkfortermsOPtion p {
                        margin: 5px 0; /* Space between links */
                        cursor: pointer;
                        white-space: nowrap; /* Prevent links from wrapping to the next line */
                      }
                      
                      /* Optional: Add styles for links */
                      .privacyPolicyLink, .termsAndConditionLink, .disclaimerLink, .contactUsLink, .refundAndCancellationPolicy {
                        font-size: 12px; /* Adjust font size */
                      }
                }
            }
        }
    }
}