.userPoriflePage{
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.585);
}

.userProfile{
    display: flex;
    align-self: center;
    width: 50%;
    height: 650px;
    background-color: rgb(0, 0, 0);
    border: 2px solid black;
    border-radius: 20px;
}
.optionsForUser{
    width: 30%;
    height:  650px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    background-color: rgb(0, 0, 0);
    display: flex;
    flex-direction: column; 
}

.optionsForUser h3{
    align-self: center;
    color: white;
}

.optionsGivenToUser{
    margin-top: 40px;
    list-style: none;
    list-style-type: none;
    font-size: 20px;
}
.optionsGivenToUser hr{
    width: 100%;
    margin-left: -0px;
    border: 1px solid rgb(255, 255, 255);
}
.optionsGivenToUser li{
    margin-left: -40px;
    margin-bottom: -10px;
    height:50px;
    color:white;
}
.optionsForUser li span{
    margin-left: 30px;
}

.optionsForUser li:hover{
    cursor: pointer;
}

.activeUser{
    background-color: white;
    font-weight: 500;
}

.activeUser span{
    color: black;
}

.activeUser:hover{
    background-color: white;
    color: black;
}
.userInfoIconUser{
    height: 70px;
    width: 70px;
    font-size: 18px;
    cursor: pointer;
    border-radius: 50%;
    overflow: hidden;
    background-color: #b7b6b6;
    border:1px solid black;
    align-self: center;
    margin-top: 30px;
  }
  .userInfoIconUser img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the entire space */
}

  .userInfoIconUser svg{
    width:80px;
    height:80px;
    margin-top: -2%;
    margin-left: -28%;
  }

  .closeArea{
    width: 5%;
    height: 100%;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: white;
  }

  .asATourist form,
  .asATourGuide form{
    margin:10px;

  }

  .closeUserProfile{
    font-size:28px;
    font-weight: bold;
    float:right;
    margin-right:50%;
    color: #626161;
  }
  
  .closeUserProfile:hover{
    cursor: pointer;
    color:#000000;
  }


  .asATourist form input[type='text'],
  .asATourGuide form input[type='text']{
    background-color: rgb(244, 243, 243);
    width: 200px;
    padding: 10px;
    border: none;
    border: 1px solid  rgb(172, 172, 172);
    border-radius: 5px;
  }

  .select-style {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 16px;
    width: 300px;
    border-radius: 5px;
    box-sizing: border-box;
  }

  .asATourist form input[type='text']:focus,
  .asATourGuide form input[type='text']:focus{
    border: none;
    background-color: 1px solid  rgb(172, 172, 172);
  }
  .asATourGuide{
    width: 75%;
    height:  650px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

.file-upload-wrapper {
    position: relative;
    width: 100%;
    max-width: 420px;
    margin: 0 auto;
    display: flex;
  }
  
  .file-upload-input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
  
  .file-upload-label {
    
    font-size: 16px;
    font-weight: 700;
    color: white;
    background-color: #000000;
    padding: 10px 20px;
    border-radius: 5px;
    height: 20px;
    cursor: pointer;
    text-align: center;
  }
  
  .file-upload-label:hover {
    background-color: #000000;
  }
  
  .file-upload-label:active {
    background-color: #000000;
  }

  .file-upload-name {
    font-size: 14px;
    width: 167px;
    height: 30px;
    color: #333;
    padding-top: 10px;
    padding-left: 10px;
    background-color: rgb(244, 243, 243);
    border: 1px solid  rgb(172, 172, 172);
    border-radius: 5px;
    margin-top: 0px;
    margin-right: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .file-upload-name:hover{
    cursor: pointer;
  }

  .globalPreferences{
    width: 75%;
    height: 95vh;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .travelHistory{
    width: 75%;
    height: 95vh;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }




  .asATourist{
    width: 75%;
    height: 650px;
    background-color: white;
    font-family: Arial, sans-serif;
    display: flex;
    flex-direction: column;
    align-self: center;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  .user-profile {
    font-family: Arial, sans-serif;
    width: 500px;
    margin-top:30px;
    margin-left: 15%;
  }

  .info-item-row{
    display: flex;
    flex-direction: column;
  }
  
  .privacy-details{
    z-index: 1;
    width: 106%;
    margin-top: 40px;
    height: 200px;
    margin-left: 20px;
    display: flex;
    flex-direction: row;
    justify-content:center;
    align-items: center;
  }

  .privacy-details-benefits{
    width: 200px;
    height:200px;
    border: 1px solid rgb(134, 134, 134);
    border-radius: 20px;
    display: flex;
    flex-direction:column;
    margin-right: 60px;
    padding: 0px 20px;
  }

  h2 {
    margin-top: 40px;
    font-size: 30px;
    margin-bottom: 50px;
    margin-left: 30px;
  }
  
  .info-item {
    display: flex;
    justify-content: space-between;
    
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
  }
  
  .label-info {
    font-weight: bold;
  }
  
  .value-info, .edit-button, .asATourist input {
    text-align: left;
  }
  
  .asATourist input{
    border: none;
    border: 1px solid #c0bebe;
    color: #000000;
    background: none;
    text-align: left;
    margin-top: 5px;
    padding-left: 5px;
    height: 30px;
    border-radius: 5px;
    width: 320px;
  }
  
  .edit-button {
    margin-top: -20px;
    border: none;
    background: none;
    color: #007bff;
    cursor: pointer;
  }
  
  .edit-button:hover {
    text-decoration: underline;
  }
  
  .label-info{
    margin-bottom:5px;
  }

  .phoneNumber{
    width: 415px;
    position: relative;
    margin-bottom: 30px;
    height: 30px;
    margin-top: 20px;
  }

  .phoneNumber button{
    margin-top: -35px;
    width: 80px;
    z-index: 3;
    color: white;
    background-color: black;
    float: right;
    font-size: 15px;
    height: 35px;
    border-radius: 8px;

  }

  .phoneNumber button:hover{
    cursor: pointer;
  }

  .phone-Number-verification{
    margin-top: -35px;
    width: 80px;
    z-index: 3;
    color: white;
    background-color: black;
    float: right;
    font-size: 15px;
    height: 35px;
    border-radius: 8px;
  }

  .phone-Number-verification:hover{
    cursor: pointer;
  }

  .plans{
    margin-top: 20px;
    display: column;
    flex-direction: row;
    justify-content:space-between;
    align-items: center;
    width: 500px;
    height: 200px;
  }

  .plan{
    border: 2px solid black;
    border-radius: 10px;
    height: 200px;
    width: 150px;
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
  }
  .plan h2{
    margin-top: -10px;
    font-size: 18px;
    margin-bottom: 20px;
    margin-left: -10px;
  }

  .plan-heading{
    padding-top: 10px;
  }

  .purchase-subscription{
    background-color: #000000;
    color: white;
    padding: 8px 10px;
    border-radius: 10px;
    margin-top: -10px;
  }

  .purchase-subscription:hover{
    cursor: pointer;
  }

  .table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
  }

  .th-td {
    padding: 5px;
    border: 1px solid #ddd;
    text-align: left;
    font-size: 13px;
  }

  .th {
    background-color: #f2f2f2;
  }

  .no-results {
    text-align: center;
    padding: 10px;
    border: 1px solid #ddd;
  }

  .price-container {
    position: relative;
    display: inline-block;
    text-align: center;
  }
  
  .original-price {
    text-decoration: line-through;
    font-weight: bold;
    /* padding-bottom: -20px;
    margin-bottom: -30px; */
  }
  
  .discounted-price {
    font-weight: bold;
  }
  .special-offer {
    margin-bottom: 10px;
    display: block;
    font-weight: bold;
  }  


  @media (max-width:1290px){
    .userProfile{
      margin-top: -10%;
      width: 90%;
      height: 600px;
    }
    .asATourist{
      height: 600px;
    }
    .optionsForUser{
      height: 600px;
    }
    @media (max-width:1000px){
      .userProfile{
        width: 95%;
      }
      @media (max-width:940px) {
        .userInfoIconUser{
          height: 60px;
          width: 60px;
          font-size: 12px;
          margin-top: 30px;
        }
        .userInfoIconUser svg{
          width:80px;
          height:80px;
          margin-top: -2%;
          margin-left: -28%;
        }
        .userProfile{
          margin-top: -15%;
          height: 550px;
        }
        .asATourist{
          height: 550px;
        }
        .optionsForUser{
          width: 30%;
          height:  550px;
          border-top-left-radius: 20px;
          border-bottom-left-radius: 20px;
          background-color: rgb(0, 0, 0);
          display: flex;
          flex-direction: column;
        }
        .optionsForUser h3{
            align-self: center;
            color: white;
        }  
        .optionsGivenToUser{
            margin-top: 40px;
            list-style: none;
            list-style-type: none;
            font-size: 15px;
            
        }
        .optionsForUser li span{
            margin-left: 20px;
        }
        .user-profile {
          font-family: Arial, sans-serif;
          width: 400px;
          
          margin-top:20px;
          margin-left: 10%;
        }
        .label-info{
          font-size: 14px;
        }
        .value-info{
          font-size: 12px;
        }
        .phoneNumber{
          width: 310px;
        }
        .phoneNumber input{
          width: 210px;
          font-size: 12px;
          height: 35px;
        }
        .phoneNumber button{
          font-size: 12px;
          width: 70px;
          height: 35px;
        }
        .plans{
          margin-top: 20px;
          display: column;
          flex-direction: row;
          justify-content:space-between;
          align-items: center;
          width: 470px;
          height: 200px;
        }
        .plan{
          width: 150px;
        }

        .table {
          width: 100%;
          border-collapse: collapse;
          margin: 20px 0;
        }

        .th-td {
          padding: 5px;
          border: 1px solid #ddd;
          text-align: center;
        }

        .th {
          background-color: #f2f2f2;
        }

        .no-results {
          text-align: center;
          padding: 10px;
          border: 1px solid #ddd;
        }


        @media (max-width:780px) {
          .plans{
            margin-top: 10px;
            width: 400px;
            height: 200px;
          }
          .plan{
            height: 150px;
            width: 110px;
          }
          .plan h2{
            font-size: 13px;
          }
          .plan ul li{
            font-size: 10px;
          }
          .plan button{
            font-size: 10px;
          }
        }
        @media (max-width:695px){
          .userProfile{
            height: 490px;
          }
          .optionsForUser{
            display: none;
          }
          .asATourist{
            border-top-left-radius:20px ;
            border-bottom-left-radius:20px ;
            width: 95%;
            height: 490px;
          }
           
          .optionsForUser li span{
              margin-left: 20px;
          }
          .user-profile{
            margin-top:10px;
            width: 90%;
          }
          .user-profile h2{
            font-size: 25px;
            margin-bottom: 15px;
            margin-left:0%;
          }
          .label-info{
            font-size: 12px;
          }
          .value-info{
            font-size: 10px;
          }
          .phoneNumber{
            width: 250px;
          }
          .phoneNumber input{
            width: 150px;
            font-size: 12px;
            height: 35px;
          }
          .phoneNumber button{
            font-size: 12px;
            width: 70px;
            height: 38px;
            margin-top:-39px;
          }
          .mobile-view-buttons{
            margin-top: -10px;
            margin-left: -20px;
            margin-right: 10px;
            display: flex;
            flex-direction: row;
            gap:0;
          }
          .mobile-view-buttons button {
            margin: 0;
            padding: 10px 15px;
            border: none; 
            border-radius: 0; 
            flex: 1;
            text-align: center;
          }
          .table {
            width: 100%;
            border-collapse: collapse;
            margin: 20px 0;
          }
  
          .th-td {
            padding: 1%;
            border: 1px solid #ddd;
            text-align: center;
            font-size: 60%;
          }
  
          .th {
            padding: 1%;
            font-size: 80%;
            background-color: #f2f2f2;
          }
  
          .no-results {
            text-align: center;
            padding: 10px;
            border: 1px solid #ddd;
          }
          .plans{
            margin-top: 10px;
            width: 400px;
            height: 200px;
          }
          .plan{
            height: 150px;
            width: 110px;
          }
          .plan h2{
            font-size: 13px;
          }
          .plan ul li{
            font-size: 10px;
          }
          .plan button{
            font-size: 10px;
          }
          @media (max-width:430px){
            .mobile-view-buttons button {
              margin: 0;
              padding: 8px 10px;
              border: none; 
              border-radius: 0; 
              flex: 1;
              text-align: center;
            }
            .plans{
              margin-top: 0px;
              width: 300px;
              height: 200px;
              margin-bottom: 10px;
            }
            .plan{
              height: 130px;
              width: 90px;
            }
            .plan h2{
              font-size: 10px;
            }
            .plan ul li{
              margin-left: -10px;
              font-size: 8px;
            }
            .plan button{
              font-size: 8px;
            }
            .table {
              width: 95%;
              border-collapse: collapse;
              margin: 10px 0;
            }
    
            .th-td {
              padding: 1%;
              border: 1px solid #ddd;
              text-align: center;
              font-size: 60%;
            }
    
            .th {
              padding: 1%;
              font-size: 70%;
              background-color: #f2f2f2;
            }
    
            .no-results {
              text-align: center;
              padding: 10px;
              border: 1px solid #ddd;
              font-size: 60%;
            }
            @media (max-width:400px){
              .mobile-view-buttons button {
                font-size: 11px;
                margin: 0;
                margin-top: 3px;
                padding: 8px 10px;
                border: none; 
                border-radius: 0; 
                flex: 1;
                text-align: center;
              }
              .plans{
                margin-top: 0px;
                width: 280px;
                height: 200px;
                margin-bottom: 10px;
              }
              .plan{
                height: 130px;
                width: 80px;
              }
              .plan h2{
                font-size: 8px;
              }
              .plan ul li{
                font-size: 6px;
              }
              .plan button{
                font-size: 6px;
              }
              .table {
                width: 85%;
                border-collapse: collapse;
                margin: 20px 0;
              }
      
              .th-td {
                padding: 1%;
                border: 1px solid #ddd;
                text-align: center;
                font-size: 60%;
              }
      
              .th {
                padding: 1%;
                font-size: 77%;
                background-color: #f2f2f2;
              }
      
              .no-results {
                text-align: center;
                padding: 10px;
                border: 1px solid #ddd;
              }
              @media (max-width:330px){
                .plans{
                  width:260px
                }
                .table {
                  width: 85%;
                  border-collapse: collapse;
                  margin: 20px 0;
                }
        
                .th-td {
                  padding: 1%;
                  border: 1px solid #ddd;
                  text-align: center;
                  font-size: 60%;
                }
        
                .th {
                  padding: 1%;
                  font-size: 77%;
                  background-color: #f2f2f2;
                }
              }
            }
          }
        }
      }
    }
  }



  .audio-preference-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px; /* Restrict width on larger screens */
    margin: auto;
}

.custom-dropdown {
    width: 300px;
    padding: 12px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 8px;
    padding-right: 8px;
    font-size: 16px;
    border: 2px solid #ccc;
    border-radius: 8px;
    background-color: #fff;
    cursor: pointer;
    transition: all 0.3s ease;
}

/* Hover and focus effects */
.custom-dropdown:hover,
.custom-dropdown:focus {
    border-color: #007bff;
    outline: none;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .audio-preference-container {
        max-width: 100px; /* Full width on smaller screens */
    }

    .custom-dropdown {
        font-size: 14px;
        padding: 10px;
    }
}
