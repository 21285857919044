.subscription-plan-heading{
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.custom-bullet {
    list-style: none;
    position: relative;
    padding-left: 20px;
  }
  
  .custom-bullet::before {
    content: '✔';
    position: absolute;
    left: -20px;
    top: 50%;
    transform: translateY(-50%);
    color: rgb(102, 106, 102);
    background-color: rgb(209, 205, 205);
    border: 2px solid gray;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
  }
  