.Home {
  margin: 0;
  padding: 0;
  background-image: url("/public/AIbackground.jpeg");
  height: 780px;
  background-size: cover;
}

.tranparency{
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  width: 100%;
  height: 780px;
  font-size: 28px;
}

.tranparency div{
  align-items: start;
  margin-left: 100px;
  width: 700px;
  color:white;
}

.tranparency div h1{
  font-size: 70px;
}

.tranparency div p{
  font-size: 27px;
  margin-top: -10px;
}

@media only screen and (max-width: 770px){
  
  .tranparency div{
    align-items: start;
    margin-left: 50px;
    width: 500px;
    color:white;
  }
  
  .tranparency div h1{
    font-size: 50px;
  }
  
  .tranparency div p{
    font-size: 21px;
    margin-top: -10px;
  }
  @media only screen and (max-width: 495px){
    .tranparency div{
      align-items: start;
      margin-left: 30px;
      width: 400px;
      color:white;
    }
    
    .tranparency div h1{
      font-size: 40px;
    }
    
    .tranparency div p{
      font-size: 18px;
      margin-top: -10px;
    }
    @media only screen and (max-width: 418px){
      .tranparency div{
        align-items: start;
        margin-left: 30px;
        width: 350px;
        color:white;
      }
      
      .tranparency div h1{
        font-size: 35px;
      }
      
      .tranparency div p{
        font-size: 15px;
        margin-top: -10px;
      }
      @media only screen and (max-width: 418px){
        .tranparency div{
          align-items: start;
          margin-left: 30px;
          margin-top: -200px;
          width: 360px;
          color:white;
        }
        
        .tranparency div h1{
          font-size: 40px;
        }
        
        .tranparency div p{
          font-size: 15px;
          margin-top: -10px;
        }
        @media only screen and (max-width: 354px){
          .tranparency div{
            align-items: start;
            margin-left: 20px;
            width: 300px;
            color:white;
          }
          
          .tranparency div h1{
            font-size: 30px;
          }
          
          .tranparency div p{
            font-size: 12px;
            margin-top: -10px;
          }
        }
      }
    }
  }
}