/* src/components/Popup.css */
.popupOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .popupContent {
    background-image: url('../../public/popup.png');
    background-size:cover;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    height: 80%;
    width:67%
  }
  
  .popupContent h2 {
    margin-top: 0;
  }
  
  .popupContent button {
    float: right;
    padding: 8px 20px;
    background: #ffffff6f;
    color: #000000;
    border: none;
    border-radius: 5px;
    font-size: 30px;
    font-weight: 700;
    cursor: pointer;
  }
  
  .popupContent button:hover {
    background: #ffffff;
  }

  @media (max-width:1260px){
    .popupContent {
      background-image: url('../../public/popup.png');
      background-size:cover;
      background-color: #ffffff;
      padding: 20px;
      border-radius: 10px;
      text-align: center;
      height: 85%;
      width:80%
    }
    @media (max-width:1030px){
      .popupContent {
        background-image: url('../../public/popup.png');
        background-size:cover;
        background-color: #ffffff;
        padding: 20px;
        border-radius: 10px;
        text-align: center;
        height: 75%;
        width:80%
      }
      @media (max-width:768px){
        .popupContent {
          background-image: url('../../public/popup.png');
          background-size:cover;
          background-color: #ffffff;
          padding: 20px;
          border-radius: 10px;
          text-align: center;
          height: 55%;
          width:80%
        }
        @media (max-width:620px) {
          .popupContent {
            background-image: url('../../public/popupMobile.png');
            background-size:cover;
            background-position: center;
            background-color: #ffffff;
            padding: 20px;
            border-radius: 10px;
            text-align: center;
            margin-top: 5%;
            height: 75%;
            width:80%
          }
        }
      }
    }
  }
  