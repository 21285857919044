
.WhyChooseUs{
    margin: 0;
    padding: 0;
    background-color: rgb(255, 255, 255);
    height: 625px;
    background-size: cover;
    justify-content: center;
    width:100%;
    overflow: hidden;
}

.whyChooseHeading{
    width: 90%;
    font-size: 30px;
    margin-left: 100px;
    margin-top: 6%;
}

.whyChooseHeading h2{
    margin-left: -5px;
    font-size: 50px;
    font-weight: 500;
}

.whyChooseHeading p{
    padding-top: 15px;
    font-size: 17px;
    width: 730px;
}

.positivePoints{
    height: 300px;
    display: flex;
    margin-left: 100px;
    margin-top: 50px;
}

.point{
    height: 250px;
    width: 330px;
    margin-right: 80px;
}

#expGuide{
    width: 70px;
    height: 70px;
    background-color: rgb(223, 249, 185);
    border-radius: 50%;
}

#audioAccess{
    width: 70px;
    height: 70px;
    background-color: rgb(216, 215, 255);
    border-radius: 50%;
}

#diverseOpt{
    width: 70px;
    height: 70px;
    background-color: rgb(255, 215, 215);
    border-radius: 50%;
}

.audio-svg svg{
    margin-top: 15%;
    margin-left: 12%;
    width: 50px;
    height: 50px;
    
}

.point-text h3{
    font-size: 27px;
    font-weight: 600;
}

.point-text p{
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 5px;
}



@media (max-width: 804px){
    .whyChooseHeading h2{
      font-size: 40px; 
    }
    .whyChooseHeading p{
        font-size: 18px;
        width: 600px;
    }
    .point-text h3{
        font-size: 18px;
    }    
    .point-text p{
        font-size: 12px;
    }
    @media (max-width: 717px) {
        .whyChooseHeading{
            margin-left: 50px;
        }
        .whyChooseHeading h2{
            font-size: 45px;
        }
        .whyChooseHeading p{
            font-size: 15px;
            width: 500px;
        }
        .positivePoints{
            margin-left: 50px;
        }
        .point-text h3{
            font-size: 18px;
        }    
        .point-text p{
            font-size: 12px;
        }
        @media (max-width: 664px) {
            .positivePoints{
                display: flex;
                flex-direction: column;
                justify-content:space-evenly;
            }
            .point{
                width: 450px;
                display: flex;
                flex-direction: row;
            }
            
            .point-text{
                margin-left: 10%;
            }

            .audio-svg{
                margin-top: 15px;
            }
            .audio-svg svg{
                margin-top: 12%;
                margin-left: 5%;
                width: 50px;
                height: 50px;
                
            }
            #expGuide{
                width: 100px;
                height: 70px;
                background-color: rgb(223, 249, 185);
                border-radius: 50%;
            }

            #expGuide svg{
                margin-left: 12%;
            }
            
            #audioAccess{
                width: 100px;
                height: 70px;
                background-color: rgb(216, 215, 255);
                border-radius: 50%;
            }
            
            #audioAccess svg{
                margin-left: 8%;
            }
            
            #diverseOpt{
                width: 80px;
                height: 70px;
                background-color: rgb(255, 215, 215);
                border-radius: 50%;
            }

            #diverseOpt svg{
                margin-left: 12%;
                margin-top: 15%;
            }
            @media (max-width: 580px) {
                .whyChooseHeading{
                    margin-top: 30%;
                    margin-left: 20px;

                }
                .whyChooseHeading h2{
                    font-size: 38px;
                }
                .whyChooseHeading p{
                    font-size: 12px;
                    width: 400px;
                }
                .positivePoints{
                    margin-left: 20px;
                }
                .point-text h3{
                    font-size: 16px;
                }    
                .point-text p{
                    font-size: 11px;
                }

                @media (max-width: 450px) {
                    .whyChooseHeading{
                        margin-top: 0px;
                    }
                     .WhyChooseUs{
                        height: 580px;
                     }
                    .whyChooseHeading h2{
                        font-size: 33px;
                    }
                    .whyChooseHeading p{
                        display: none;
                        font-size: 12px;
                        width: 100%;
                    }
                    .point-text{
                        width: 250px;
                    }
                    .point-text h3{
                        font-size: 16px;
                    }    
                    .point-text p{
                        font-size: 11px;
                    }
                    .audio-svg{
                        margin-top: 15px;
                    }
                    .audio-svg svg{
                        margin-top: 12%;
                        margin-left: 5%;
                        width: 50px;
                        height: 50px;
                        
                    }
                    #expGuide{
                        width: 70px;
                        height: 70px;
                        background-color: rgb(223, 249, 185);
                        border-radius: 50%;
                    }
        
                    #expGuide svg{
                        margin-left: 12%;
                    }
                    
                    #audioAccess{
                        width: 70px;
                        height: 70px;
                        background-color: rgb(216, 215, 255);
                        border-radius: 50%;
                    }
                    
                    #audioAccess svg{
                        margin-left: 8%;
                    }
                    
                    #diverseOpt{
                        width: 70px;
                        height: 70px;
                        background-color: rgb(255, 215, 215);
                        border-radius: 50%;
                    }
        
                    #diverseOpt svg{
                        margin-left: 12%;
                        margin-top: 15%;
                    }
                    
                        @media (max-width:360px){
                            .whyChooseHeading{
                                width: 400px;
                                margin-left: 10px;
                            }
                            .whyChooseHeading h2{
                                font-size: 26px;
                            }
                            .positivePoints{
                                margin-left: 10px;
                            }
                            .point-text{
                                width: 180px;
                            }
                            .audio-svg{
                                margin-top: 20px;
                            }
                        
                    }
                }
            }

              
        }
        
    }

  }