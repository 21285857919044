.tour-plans-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 4 columns for large screens */
    gap: 20px;
    margin-top: 20px;
    width:83%;
}

.tour-plan-box {
    border: 1px solid grey;
    padding: 10px;
    border-radius: 5px;
    background-color: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.add-plan-box {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed grey;
    padding: 20px;
    border-radius: 5px;
    cursor: pointer;
    background-color: #f0f0f0;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}


@media (max-width: 768px) {
    .tour-plans-grid {
        grid-template-columns: repeat(2, 1fr); /* 2 columns for medium screens */
    }
}

@media (max-width: 480px) {
    .tour-plans-grid {
        grid-template-columns: 1fr; /* 1 column for mobile screens */
    }
}


.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Make sure the modal is above other elements */
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3); /* Shadow effect */
    width: 90%;
    max-width: 500px; /* Limit width on larger screens */
    animation: slideIn 0.3s ease; /* Add a slide-in effect */
}

@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.modal-title {
    font-size: 24px;
    color: #20232a; /* Darker color for the title */
    margin-bottom: 20px;
    text-align: center;
}

.input-group {
    margin-bottom: 15px;
}

.modal-input {
    width: 80%; /* Full width inputs */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px; /* Rounded input corners */
    transition: border 0.3s; /* Transition effect */
}

.modal-input:focus {
    border-color: rgb(202, 53, 190); /* Focus color */
    outline: none; /* Remove default outline */
}

.modal-buttons {
    display: flex;
    justify-content: space-between; /* Space between buttons */
    margin-top: 20px; /* Space above buttons */
}

.modal-save-button,
.modal-cancel-button {
    padding: 10px 15px;
    border: none;
    border-radius: 5px; /* Rounded button corners */
    cursor: pointer; /* Pointer cursor on hover */
    font-weight: bold;
    transition: background-color 0.3s; /* Transition effect */
}

.modal-save-button {
    background-color: rgb(202, 53, 190); /* Save button color */
    color: white; /* Text color for save button */
}

.modal-save-button:hover {
    background-color: rgb(172, 43, 160); /* Darker shade on hover */
}

.modal-cancel-button {
    background-color: #ccc; /* Cancel button color */
    color: black; /* Text color for cancel button */
}

.modal-cancel-button:hover {
    background-color: #aaa; /* Darker shade on hover */
}

.modal-label {
    font-size: 14px; /* Adjust font size */
    color: #333; /* Darker color for better visibility */
    margin-bottom: 5px; /* Space below the label */
    display: block; /* Ensures the label takes a full line */
}

.tour-plan-box {
    background-color: #f9f9f9; /* Light background for better contrast */
    border: 1px solid #e0e0e0; /* Light border for definition */
    border-radius: 10px; /* Rounded corners for a softer look */
    padding: 20px; /* Spacing inside the box */
    margin: 10px; /* Space around the boxes */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
    transition: transform 0.3s; /* Smooth scaling on hover */
    cursor: pointer; /* Indicate interactivity */
}

.tour-plan-box:hover {
    transform: scale(1.02); /* Slightly enlarge on hover */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15); /* Deepen shadow on hover */
}

.tour-plan-box h4 {
    color: #333; /* Darker color for better readability */
    font-size: 20px; /* Slightly larger font size */
    margin: 0; /* Remove default margin */
}

.tour-plan-box p {
    color: #555; /* A softer gray for text */
    margin: 5px 0; /* Spacing between paragraphs */
}

.tour-plan-box .price {
    font-weight: bold; /* Highlight the price */
    color: #e91e63; /* Use a vibrant color for emphasis */
}


.plan-actions {
    display: flex; /* Use flexbox for layout */
    justify-content: space-around; /* Space out buttons evenly */
    margin-top: 10px; /* Add space above the button container */
}

.edit-plans-button,
.delete-button {
    background-color: #007BFF; /* Primary blue color for Edit */
    color: white; /* White text color */
    border: none; /* No border */
    border-radius: 5px; /* Rounded corners */
    padding: 10px 15px; /* Padding for larger button area */
    cursor: pointer; /* Pointer cursor on hover */
    font-size: 14px; /* Font size */
    transition: background-color 0.3s ease, transform 0.2s; /* Transition effects */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
}

/* Edit button specific styles */
.edit-plans-button {
    background-color: #007BFF; /* Blue */
}

.edit-plans-button:hover {
    background-color: #0056b3; /* Darker blue on hover */
    transform: translateY(-2px); /* Lift effect on hover */
}

/* Delete button specific styles */
.delete-button {
    background-color: #DC3545; /* Red */
}

.delete-button:hover {
    background-color: #c82333; /* Darker red on hover */
    transform: translateY(-2px); /* Lift effect on hover */
}
