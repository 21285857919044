.navbar {
  display: flex;
  z-index: 10;
  justify-content: space-between;
  align-items: center;
  color: black;
  position: fixed;
  top:0;
  width: 100%;
  height: 60px;
  background-color: rgb(255, 255, 255);
  
}

.logo {
  font-size: 35px;
  font-weight:700;
  margin-left: 6%;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
}

.logo img{
  height: 55px;
  margin-right: 30px;
  
}

.nav-bar-container{
  width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 66px;
}

.nav-links {
  font-size: 19px;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  left: 0%;
}

.dropdown-button{
  display:none;
}

@media only screen and (max-width: 1500px){
  .nav-bar-container{
    width: 90%;
  }
}

.nav-links {
  display: flex;
}

.nav-links:hover{
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .dropdown-button{
    display: block;
  }
  .nav-links {
    display:none;
  }
  .nav-links.show{
    display: flex;
    flex-direction: column;
  }
}


.nav-links li {
  margin-right: 45px;
}

.nav-links li Link {
  color: #000000;
  text-decoration: none;
}

.nav-bar-container button{
  padding: 12px 15px;
  font-size: 18px;
  margin-right: 10%;
  background-color: #000000; 
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 20px;
  font-weight: 700;
}

.nav-bar-container button:hover{
  background-color: #373737;
}

@media only screen and (max-width: 1338px){
  .nav-links{
    font-size: 16px;
  }
  @media only screen and (max-width: 1257px){
    .nav-bar-container{
      margin-left: 0%;
    }
    .nav-links{
      font-size: 14px;
    }
  }
}

.menu-icon{
  display: none;
}
.active{
  font-weight: bold;
}

.loginAndSignupPopupWindow{
  display: flex;
  position: absolute;
  top: 0px;
  align-self: center;
  justify-content: center;
  align-items: center;
  width:100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.585);
}

.logInAndSignUp{
  display: flex;
  position: absolute;
  width: 780px;
  height: 560px;
  background-color: white;
  border: 2px solid black;
  border-radius: 20px;
  
}

.leftSide{
  width: 50%;
  height: 100%;
  background-image: url('/public/SignUPandSignInSecond.jpeg');
  
  border-top-left-radius: 18px;
  border-bottom-left-radius: 18px;

}

.closeLoginAndSignUpPopUp{
  font-size:28px;
  font-weight: bold;
  float:right;
  margin-right:4%;
  color: #626161;
}

.closeLoginAndSignUpPopUp:hover{
  cursor: pointer;
  color:#000000;
}

.rightSideLogIn{
  color:black;
  width: 50%;
  height: 100%;
}

.rightSideLogIn h2{
  font-size: 45px;
  margin-left: 13%;
  margin-top: 40px;
  margin-bottom: 40px;
  font-weight: 500;
  color: #000000;

}
.rightSideLogIn input[type="text"],
.rightSideLogIn input[type="password"] {
    background-color: rgb(244, 243, 243);
    width: 70%;
    padding: 10px;
    margin-left: 10%;
    border: none;
    border-bottom: 2px solid black;
    border-radius: 5px;
}
.rightSideLogIn input[type="email"]:focus,
.rightSideLogIn input[type="password"]:focus{
  border: none;
  border-bottom: 2px solid black;
}
.errorInEmail{
  font-size: 12px;
  margin-top: 0px;
  margin-left: 15%;
  margin-bottom: 20px;
  color:red;
}
.errorInPassword{
  font-size: 12px;
  margin-top: 0px;
  margin-left: 15%;
  color:red;
}

.forgotPassword{
  font-size: 17px;  
  color: rgb(85, 0, 255);
  float: right;
  margin-right: 16%;
  margin-top: -10%;
}

.loginButton{
  margin-left: 10%;
  font-size: 20px;
  width: 75%;
  background-color: rgb(0, 0, 0);
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 10px;
  border: 1px solid black
}

.loginButton:hover{
  cursor: pointer;
}
.orStatement{
  display: flex;
}
.orLineleft{
  margin-top: 28px;
  margin-left: 18%;
  margin-right: 3%;
  width: 25%;
  height: 2px;
  background-color: #626161;
}

.orLineright{
  margin-top: 28px;
  margin-left: 2%;
  width: 25%;
  height: 2px;
  background-color: #626161;
}

.loginButtonGoogle{
  margin-left: 11%;
  font-size: 17px;
  width: 68%;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  border: none;
  border-radius: 5px;
  padding: 10px 10px;
  border: 1px solid black;
  display: flex;
  height:26px;
}

.loginButtonGoogle div{
  display: flex;
}

.loginButtonGoogle:hover{
  cursor: pointer;
}

.google-icon {
  margin-top: -4px;
  width: 20px;
  height: 20px;
  margin-left: 10px;
  margin-right: 30px;  
}

.areYouNew{
  font-size: 17px;
  margin-top: 7%;
  margin-left: 15%;
}

.backToSignInFromForgotPass{
  font-size: 17px;
  margin-left: 20%;
}
.createNewAccountLink{
  font-size: 17px;
  text-decoration: underline;
  color: rgb(43, 0, 255);
  margin-top: 10%;
}

.black{
  color: #000000;
  text-decoration: none;
}

.backToSignIn{
  font-size: 17px;
  text-decoration: none;
  color: rgb(149, 37, 0);
  font-weight: bold;
  margin-right: 16%;
  margin-top: 7%;
  margin-left: 12%;
  width: 70%;
}

.createNewAccountLink:hover,
.backToSignIn:hover,
.forgotPassword:hover{
  cursor: pointer;
  color: rgb(187, 50, 5);
}

.userInfoIcon{
  height: 45px;
  width: 45px;
  font-size: 18px;
  margin-right: 18%;
  cursor: pointer;
  border-radius: 50%;
  overflow: hidden;
  background-color: #b7b6b6;
}

.userInfoIcon svg{
  width:50px;
  height:50px;
  margin-top: -2%;
  margin-left: -25%;
}
.userInfoIcon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.userInfoIconinMobileView{
  display: none;
  height: 45px;
  width: 45px;
  margin-left: 20%;
  font-size: 18px;
  cursor: pointer;
  border-radius: 50%;
  overflow: hidden;
  background-color: #b7b6b6;
}

.userInfoIconinMobileView svg{
  width:50px;
  height:50px;
  margin-top: -2%;
  margin-left: -25%;
}
.userInfoIconinMobileView img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.dropdownMenu {
  position: absolute;
  top: 59px; 
  right: 8.2%;
  background-color: white;
  border: 1px solid #b7b6b6;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 150px;
  border-radius: 5%;
  z-index: 1000;
}

.dropdownMenu ul {
  list-style: none;
  padding: 0;
  margin: 0;
  align-items: center;
}

.dropdownMenu li {
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.dropdownMenu li:hover {
  background-color: #111111;
  color: white;
}

.dropdownMenu svg{
  margin-top: 0px;
}

.dropdownMenu span{
  margin-left: 20px;
}

#logout{
  color:red;
}

#logout:hover{
  background-color: #ea4335;
  color: white;
}

@media only screen and (max-width: 1289px){
  .navbar button{
    margin-right: 8%;
  }


  @media only screen and (max-width: 1175px){
    .nav-bar-container{
      width: 70%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 66px;
    }
    @media only screen and (max-width: 1091px){
  
      .logo {
        font-size: 35px;
        font-weight:700;
        margin-left: 7%;
      }
    
      .nav-bar-container{
        width: 70%;
        height:100vh;
      }
    
      .navbar button{
        margin-right: 5%;
      }

      @media only screen and (max-width: 1060px){

        .userInfoIconinMobileView{
          display: block;
        }

        .userInfoIcon{
          display: none;
        }

        .nav-bar-container{
          position: absolute;
          list-style: none;
          display: block;
          width:100%;
          height:100vh;
          top:65px;
          left:-120%;
          text-align: center;
          background-color: #ffffff;
          transition: all .9s;
        }
        .nav-bar-container-show {
          left:0%;
        }
        .nav-links{
          display:grid;
        }
        .nav-bar-container li{
            margin-bottom: 20px;
            margin-top: 20px;
        }
        .nav-bar-container li a{
            font-size: 20px;
        }
        .nav-bar-container button{
          margin-top: 100px;
          margin-left: 50px;
        }
        .menu-icon{
          display: block;
          margin-left: auto;
          margin-right: 8%; 
          background-color: transparent;
          font-size: 28px;
          font-weight: 600;
          border: none;
          border-style: none;
          background-color: white;
          color: #000000;
        }

        .dropdownMenu {
          position: absolute;
          top: 60px; 
          right: 42%;
          background-color: white;
          border: 1px solid #b7b6b6;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          width: 150px;
          border-radius: 5%;
          z-index: 1000;
        }

        @media only screen and (max-width:770px){
          .logInAndSignUp{
            margin-left: 0px;
            width: 430px;
          }
          .rightSideLogIn{
            width: 500px;
          }
          .leftSide{
            display: none;
          }
          .dropdownMenu {
            position: absolute;
            top: 60px; 
            right: 40%;
            background-color: white;
            border: 1px solid #b7b6b6;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            width: 150px;
            border-radius: 5%;
            z-index: 1000;
          }
          @media only screen and (max-width: 480px){
            .logo{
              font-size: 30px;
            }
            .nav-bar-container button{
              margin-top: 100px;
              margin-left: 20px;
            }
            .logInAndSignUp{
              margin-left: 0px;
              width: 300px;
              height: 440px;
            }
            
            .rightSideLogIn h2{
              font-size: 30px;
              margin-left: 20%;
              margin-top: 30px;
              margin-bottom: 20px;
              font-weight: 500;
              color: #000000;
            
            }
            .rightSideLogIn input[type="text"],
            .rightSideLogIn input[type="password"] {
                background-color: rgb(244, 243, 243);
                width: 60%;
                padding: 10px;
                margin-left: 16%;
                border: none;
                font-size: 12px;
                border-bottom: 2px solid black;
                border-radius: 5px;
            }
            .rightSideLogIn input[type="email"]:focus,
            .rightSideLogIn input[type="password"]:focus{
              border: none;
              border-bottom: 2px solid black;
            }
            .errorInEmail{
              font-size: 8px;
              margin-top: 0px;
              margin-left: 20%;
              margin-bottom: 15px;
              color:red;
            }
            .errorInPassword{
              font-size: 8px;
              margin-top: 0px;
              margin-left: 20%;
              color:red;
            }
            
            .forgotPassword{
              font-size: 12px;  
              color: rgb(85, 0, 255);
              float: right;
              margin-right: 16%;
              margin-top: -10%;
            }
            
            .loginButton{
              margin-left: 17%;
              font-size: 15px;
              width: 65%;
              margin-top: -10px;
              background-color: rgb(0, 0, 0);
              color: white;
              border: none;
              border-radius: 5px;
              padding: 10px 10px;
              border: 1px solid black
            }

            .orStatement{
              margin-top: -10px;
            }
            .orLineleft{
              margin-left: 19%;
              margin-right: 2%;
              width: 25%;
              height: 2px;
              background-color: #626161;
            }
            
            
            .loginButtonGoogle{
              margin-left: 17%;
              font-size: 15px;
              width: 59%;
              background-color: rgb(255, 255, 255);
              color: rgb(0, 0, 0);
              border: none;
              border-radius: 5px;
              padding: 10px 10px;
              border: 1px solid black;
              display: flex;
              height:26px;
            }
            
            .loginButtonGoogle:hover{
              cursor: pointer;
            }
            
            .google-icon {
              margin-top: -4px;
              width: 20px;
              height: 20px;
              margin-left: 5px;
              margin-right:15px;              
            }
            
            .areYouNew{
              font-size: 13px;
              margin-top: 5%;
              margin-left: 15%;
            }
            
            .backToSignInFromForgotPass{
              font-size: 17px;
              margin-left: 20%;
            }
            .createNewAccountLink{
              font-size: 13px;
              text-decoration: underline;
              color: rgb(43, 0, 255);
            }
            
            .black{
              color: #000000;
              text-decoration: none;
            }
            
            .backToSignIn{
              font-size: 17px;
              text-decoration: none;
              color: rgb(149, 37, 0);
              font-weight: bold;
              margin-right: 16%;
              margin-top: 7%;
              margin-left: 12%;
              width: 70%;
            }
            .logo img{
              margin-right: 15px;
            }
            .dropdownMenu {
              position: absolute;
              top: 40px; 
              right: 32%;
              background-color: white;
              border: 1px solid #b7b6b6;
              box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
              width: 150px;
              border-radius: 5%;
              z-index: 1000;
            }
            @media only screen and (max-width:330px) {
                .dropdownMenu{
                  right:27%
                }              
            }
          }
        }
      }
    }
    
  }
}