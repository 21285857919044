#edit-tour-guide-profile-page{
    margin-left:270px;
    height: 100vh;
}

.top-background-tour-guide-edit-section{
    height: 150px;
    background-image: url('../../../images/background_image_tour_guide.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

#edit-profie-section{
    padding-left: 100px;
    height: 400px;
}

#tour-guide-profile-section-profile-picture-section{
    width: 400px;
    height: 80px;
    display: flex;
}

.tour-guide-profile-picture{
    background-color: rgb(223, 223, 223);
    transform: translateY(-50%);
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 3px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin-right: 10px;
}

#tour-guide-profile-setup{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.tour-operator-dashboard-opener{
    float: right;
    margin: 30px;
    font-size: 20px;
    display: none;
}

@media only screen and (max-width: 1036px){
    #tour-guide-profile-setup{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
    }

    #edit-tour-guide-profile-page{
        margin-left:0px;
    }

    #tour-guide-profile-setup{
        margin-left: -10%;
    }
    .tour-operator-dashboard-opener{
        display: block;
    }
}

#tour-guide-profile-setup h2 {
    grid-column: 1 / -1;
}

#tour-guide-profile-setup div{
    display: flex;
    flex-direction: column;
}

#tour-guide-profile-setup div label{
    font-weight: 500;
    margin-bottom: 10px;
}

#tour-guide-profile-setup div input{
    width: 300px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px; 
    font-size: 16px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s ease;
}

#tour-guide-profile-setup div input:focus {
    border-color: #000000;
    outline: none; 
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

#tour-guide-profile-setup div textarea{
    width: 300px;
    height: 20px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px; 
    font-size: 16px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s ease;
}

#tour-guide-profile-setup div textarea:focus {
    border-color: #000000;
    outline: none; 
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

#tour-guide-profile-setup div select{
    width: 320px;
    height: 40px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px; 
    font-size: 16px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s ease;
    
}

#tour-guide-profile-setup div select:focus {
    border-color: #000000;
    outline: none; 
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}


.phone-input-container {
    display: grid;
    grid-template-columns: 80px auto;
    gap: 10px;
}

#countryCode {
    width: 50px;
    height: 40px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s ease;
}

.phone-input-container input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s ease;
}

#state {
    height: 30px;
    padding: 5px; 
    font-size: 14px; 
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: white;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s ease;
}

.add-a-document{
    background-color: #e8e4e4;
    border: 1px solid #a2a1a1;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s ease;
}

.add-a-document:hover {
    border-color: #000000;
    outline: none; 
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

@media only screen and (max-width: 707px){
    #tour-guide-profile-setup{
        grid-template-columns: 1fr;
        margin-right: 10%;
    }

    #tour-guide-profile-setup div input{
        width: 100%;
    }
    .phone-input-container {
        display: grid;
        grid-template-columns: 1fr 4fr;
        gap: 10px;
    }
    #tour-guide-profile-setup div textarea{
        width: 100%;
    }
    #tour-guide-profile-setup div select{
        width: 100%;
    }
}

@media only screen and (max-width: 515px){
    #tour-guide-profile-section-profile-picture-section{
        width: 400px;
        height: 80px;
        display: flex;
        margin-left: -90px;
    }
}

@media only screen and (max-width: 414px){
    #tour-guide-profile-section-profile-picture-section{
        width: 300px;
    }
    .tour-guide-profile-picture{
        background-color: rgb(223, 223, 223);
        transform: translateY(-30%);
        width: 100px;
        height: 100px;
        border-radius: 50%;
        border: 3px solid black;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        margin-right: 10px;
    }

    #tour-guide-profile-setup{
        margin-left: -80px;
        width: 300px;
        grid-template-columns: 1fr;
        margin-right: 10%;
    }

    #tour-guide-profile-setup div input{
        width: 90%;
    }
    .phone-input-container {
        width: 90%;
        display: grid;
        grid-template-columns: 1fr 4fr;
        gap: 10px;
    }
    #tour-guide-profile-setup div textarea{
        width: 90%;
    }
    #tour-guide-profile-setup div select{
        width: 90%;
    }
}