#tour-guide-profile-dashboard{
    width:270px;
    height:100vh;
    background-color: rgb(255, 255, 255);
    border-right: 2px solid black;
    position: fixed;
}

@media only screen and (max-width: 1036px){
    #tour-guide-profile-dashboard{
        left: -280px;
    }
}


#tour-guide-dashborad-options{
    list-style-type: none;
    display: flex;
    flex-direction: column;
    padding: 0px;
    align-items: center;
}

.option-for-tour-guide-dashboard{
    margin-bottom: 10px;
    border-radius: 10px;
    font-size: 20px;
    font-weight: 400;
    display: flex;
    align-items: center;
    width: 200px;
}

.option-for-tour-guide-dashboard div{
    margin-top: 5px;
    padding-left: 10px;
    width: 65px;
    margin-right: -15px;
}

.option-for-tour-guide-dashboard div svg{
    height: 27px;
}

.option-for-tour-guide-dashboard svg path {
    transition: fill 0.3s ease;
}

.option-for-tour-guide-dashboard svg path{
    fill: rgb(120, 119, 119);
}

.option-for-tour-guide-dashboard p{
    font-size: 18px;
    color: rgb(120, 119, 119);
}

.option-for-tour-guide-dashboard:hover{
    cursor: pointer;
}

.option-for-tour-guide-dashboard:hover svg path{
    fill: black;
}

.option-for-tour-guide-dashboard:hover p{
    color: black;
}

.option-for-tour-guide-dashboard-active{
    margin-bottom: 10px;
    border-radius: 10px;
    font-size: 20px;
    font-weight: 400;
    display: flex;
    align-items: center;
    width: 230px;
    background-color: rgb(244, 244, 244);
}

.option-for-tour-guide-dashboard-active div{
    margin-top: 5px;
    padding-left: 10px;
    width: 65px;
    margin-right: -15px;
}

.option-for-tour-guide-dashboard-active div svg{
    height: 27px;
}

.option-for-tour-guide-dashboard-active svg path {
    transition: fill 0.3s ease;
}

.option-for-tour-guide-dashboard-active svg path{
    fill: rgb(120, 119, 119);
}

.option-for-tour-guide-dashboard-active p{
    font-size: 18px;
    color: rgb(120, 119, 119);
}

.option-for-tour-guide-dashboard-active:hover{
    cursor: pointer;
}

.option-for-tour-guide-dashboard-active:hover svg path{
    fill: black;
}

.option-for-tour-guide-dashboard-active:hover p{
    color: black;
}
