.touristDestinationPage{
    margin-top: 90px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.touristDesti{
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    gap: 40px; 
    width: 100%;
    margin-left: 50px;
    margin-right: 40px;
}

.destinationTouristPlace{
    height:420px;
    width: 22%;
    background-color: white;
    border: 2px solid black;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    overflow: hidden;
}

.imageSectionofdestinations{
    width: 100%;
    height: 65%;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
}

.destinationTouristPlace h2{
    margin: 13px;
    font-size: 20px;
    font-weight:500;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
}
.destinationTouristPlace p{
    padding-left: 13px;
    margin-top: -15px;
    margin-bottom: 20px;
}

.exploreButtonforTouristDesti{
    margin-top: 0px;
    margin-left: 31%;
    background-color: black;
    color:white;
    font-size: 17px;
    border: none;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 8px;
}

.exploreButtonforTouristDesti:hover{
    cursor: pointer;
}

@media (max-width:1330px){
    .touristDesti h2{
        font-size: 18px;
    }
}

@media(max-width:1210px){
    .destinationTouristPlace{
        height:420px;
        width: 30%;
        background-color: white;
        border: 2px solid black;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        overflow: hidden;
    }

    .touristDesti{
        margin-top: 30px;
        display: flex;
        flex-wrap: wrap;
        gap: 40px; 
        width: 100%;
        margin-left: 50px;
        margin-right: 40px;
    }
    .touristDesti h2{
        font-size: 20px;
    }
    @media (max-width:1005px) {
        .destinationTouristPlace{
            height:420px;
            width: 29%;
            background-color: white;
            border: 2px solid black;
            border-radius: 20px;
            display: flex;
            flex-direction: column;
            margin-top: 20px;
            overflow: hidden;
        }
    
        .touristDesti{
            margin-top: 30px;
            display: flex;
            flex-wrap: wrap;
            gap: 40px; 
            width: 100%;
            margin-left: 50px;
            margin-right: 40px;
        }
        .touristDesti h2{
            font-size: 18px;
        }
        @media (max-width:940px) {
            .touristDesti h2{
                font-size: 16px;
            }

            .touristDesti p{
                font-size: 12px;
            }
        }
        @media (max-width:800px) {
            .destinationTouristPlace{
                height:420px;
                width: 46%;
                background-color: white;
                border: 2px solid black;
                border-radius: 20px;
                display: flex;
                flex-direction: column;
                margin-top: 20px;
                overflow: hidden;
            }
        
            .touristDesti{
                margin-top: 30px;
                display: flex;
                flex-wrap: wrap;
                gap: 40px; 
                width: 100%;
                margin-left: 50px;
                margin-right: 40px;
            }

            .touristDesti h2{
                font-size: 20px;
            }

            .touristDesti p{
                font-size: 16px;
            }

            @media (max-width:700px) {
                .destinationTouristPlace{
                    height:350px;
                    width: 46%;
                    background-color: white;
                    border: 2px solid black;
                    border-radius: 20px;
                    display: flex;
                    flex-direction: column;
                    margin-top: 20px;
                    overflow: hidden;
                }
            
                .touristDesti{
                    margin-top: 30px;
                    display: flex;
                    flex-wrap: wrap;
                    gap: 40px; 
                    width: 100%;
                    margin-left: 50px;
                    margin-right: 40px;
                }

                .touristDesti h2{
                    font-size: 18px;
                }

                .touristDesti p{
                    font-size: 14px;
                }

                .exploreButtonforTouristDesti{
                    margin-top: -10px;
                    margin-left: 31%;
                    background-color: black;
                    color:white;
                    font-size: 17px;
                    border: none;
                    padding-left: 15px;
                    padding-right: 15px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    border-radius: 8px;
                }
                    @media (max-width:500px) {
                        .destinationTouristPlace{
                            height:250px;
                            width: 47%;
                            background-color: white;
                            border: 2px solid black;
                            border-radius: 10px;
                            display: flex;
                            flex-direction: column;
                            margin-top: 20px;
                            overflow: hidden;
                        }
                    
                        .touristDesti{
                            margin-top: 30px;
                            display: flex;
                            flex-wrap: wrap;
                            gap: 10px; 
                            width: 100%;
                            margin-left: 10px;
                            margin-right: 10px;
                        }
                        .touristDesti h2{
                            font-size: 12px;
                        }
                        .touristDesti p{
                            display: none;
                        }
                        .exploreButtonforTouristDesti{
                            margin-top: 0px;
                            margin-left: 25%;
                            background-color: black;
                            color:white;
                            font-size: 17px;
                            border: none;
                            padding-left: 15px;
                            padding-right: 15px;
                            padding-top: 10px;
                            padding-bottom: 10px;
                            border-radius: 8px;
                        }
                        .imageSectionofdestinations{
                            width: 100%;
                            height: 65%;
                            border-top-left-radius: 8px;
                            border-top-right-radius: 8px;
                        }
                        @media (max-width:400px) {
                            .imageSectionofdestinations{
                                height: 200px;
                            }
                            .destinationTouristPlace{
                                height:250px;
                                width: 48%;
                                background-color: white;
                                border: 2px solid black;
                                border-radius: 10px;
                                display: flex;
                                flex-direction: column;
                                margin-top: 20px;
                                overflow: hidden;
                            }
                        
                            .touristDesti{
                                margin: 30px;
                                display: flex;
                                flex-wrap: wrap;
                                gap: 5px; 
                                width: 100%;
                                margin-left: 5px;
                                margin-right: 5px;
                            }
                            .touristDesti h2{
                                margin: 10px;
                                font-size: 12px;
                            }
                            .touristDesti p{
                                display: none;
                            }
                            .exploreButtonforTouristDesti{
                                margin-top: 0px;
                                margin-left: 25%;
                                background-color: black;
                                color:white;
                                font-size: 12px;
                                border: none;
                                padding-left: 15px;
                                padding-right: 15px;
                                padding-top: 10px;
                                padding-bottom: 10px;
                                border-radius: 8px;
                            }
                            .imageSectionofdestinations{
                                width: 100%;
                                height: 70%;
                                border-top-left-radius: 8px;
                                border-top-right-radius: 8px;
                            }
                        } 
                        @media (max-width:330px) {
                            .destinationTouristPlace{
                                height:225px;
                                width: 47%;
                                background-color: white;
                                border: 2px solid black;
                                border-radius: 10px;
                                display: flex;
                                flex-direction: column;
                                margin-top: 20px;
                                overflow: hidden;
                            }
                        
                            .touristDesti{
                                margin-top: 30px;
                                display: flex;
                                flex-wrap: wrap;
                                gap: 10px; 
                                width: 100%;
                                margin-left: 10px;
                                margin-right: 10px;
                            }
                            .touristDesti h2{
                                margin: 8px;
                                font-size: 10px;
                            }
                            .touristDesti p{
                                display: none;
                            }
                            .exploreButtonforTouristDesti{
                                margin-top: 0px;
                                margin-left: 25%;
                                background-color: black;
                                color:white;
                                font-size: 10px;
                                border: none;
                                padding-left: 15px;
                                padding-right: 15px;
                                padding-top: 10px;
                                padding-bottom: 10px;
                                border-radius: 8px;
                            }
                            .imageSectionofdestinations{
                                width: 100%;
                                height: 72%;
                                border-top-left-radius: 8px;
                                border-top-right-radius: 8px;
                            }
                        }                       
                    }
            }
        }
    }
}