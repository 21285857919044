.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000; /* Make sure it's above other elements */
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    width: 300px;
    text-align: center;
}
.modal-actions {
    margin: 20px 0;
}

.modal-actions button {
    margin: 0 10px;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.modal-actions button:hover {
    background-color: #e0e0e0;
}

.modal-close {
    background-color: #f44336; /* Red color for close */
    color: white;
}
