.explore-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }
  
  .city-image {
    background-size: cover;
    width: 70%;
    height: 500px;
    object-fit: cover;
    border-radius: 10px;
    margin-bottom: 20px;
    margin-top: 100px;
  }
  
  .text-container {
    /* background-color: #994292; */
    text-align: center;
    width: 65%;

  }
  
  .text-container p {
    font-size: 18px;
    color: #555;
  }

  .book-tour-guide{
    font-size: 20px;
    color:white;
    background-color: black;
    padding: 15px;
    border-radius: 10px;
    margin-top: 25px;
  }

  .book-tour-guide:hover{
    cursor: pointer;
    background-color: rgb(59, 58, 58);
  }
  
  .book-tour-operator{
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top:0;
    height:100vh;
    width:100vw;
    background-color: rgba(0, 0, 0, 0.433);
    z-index: 100;
  }

  .popUp-booking-tour-guide{
    background-color: white;
    width: 500px;
    height: 450px;
  }

  .popUp-audio-book{
    background-color: white;
    width: 400px;
    height: 500px;
  }

  .popUp-audio-book-inner{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 450px;
    
  }

  .pickup-form {
    max-width: 400px;
    margin: 0 auto;
    padding: 1rem;
    border-radius: 8px;
  }
  
  .pickup-form div {
    margin-bottom: 1rem;
  }
  
  .pickup-form label {
    display: block;
    margin-bottom: 0.5rem;
  }
  
  .pickup-form input {
    width: 90%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .pickup-form button {
    width: 94%;
    padding: 0.75rem;
    background-color: #000000;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .pickup-form button:hover {
    background-color: #252525;
  }
  .audio-container {
    width: 81%;
    height: 70px;
    background-color: #f0f0f0;
    border-radius: 10px;

  }
  
  .audio-element {
    width: 100%;
    height: 100%;
  }
  
  .div-element {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    font-weight: 500;
    margin-top: 5px;
  }

  .grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 20px;
    padding: 20px;
    height: 350px; 
    width: 90%;
    overflow-y: scroll;
}

.grid-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  padding: 20px;
  text-align: center;
  border-radius: 10px;
}

.grid-item:hover{
  cursor: pointer;
}

.profile-picture {
  width: 50px; 
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
}


.operator-name {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}



@media (max-width:480px){
  .grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
    gap: 10px;
    padding: 10px;
    height: 370px; 
    width: 95%;
    overflow-y: scroll;
  }
  .grid-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    padding: 1cqi;
    text-align: center;
    border-radius: 10px;
  }
  .profile-picture {
    width: 50px; 
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 10px;
  }
  .operator-name {
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }
}

  @media (max-width:450px) {
    .popUp-booking-tour-guide{
      width: 350px;
    }
    .pickup-form{
      width: 90%;
    }
  }
  @media (max-width:330px) {
    .popUp-booking-tour-guide{
      width: 300px;
    }
    .popUp-audio-book-inner{
      width: 300px;
    }
    .pickup-form{
      width: 95%;
    }
  }

  @media (max-width:770px){
    .city-image{
      height:300px;
    }
    .text-container p{
      font-size: 14px
    }
  } 
  
  






  
/* styles.css */
.book-tour-operator {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  animation: fadeIn 0.3s ease-in-out;
}

.popUp-audio-book {
  background: linear-gradient(135deg, #f8f9fa, #ffffff);
  border-radius: 15px;
  width: 90%;
  max-width: 400px;
  padding: 25px;
  padding-right: 0px;
  padding-left: 0px;
  position: relative;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  animation: slideUp 0.3s ease-in-out;
}


.popUp-audio-book-inner{
  margin-left: 0%;
  width: 100%;
}

.popUp-audio-book-inner h2 {
  margin-left:-10%;
  margin-top: 0px;
  font-size: 26px;
  color: #2c3e50;
  margin-bottom: 15px;
  font-family: 'Roboto', sans-serif;
}

.select-option-given-to-user{
  background: linear-gradient(135deg, #f8f9fa, #ffffff);
  border-radius: 15px;
  width: 90%;
  max-width: 400px;
  padding: 25px;
  position: relative;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  animation: slideUp 0.3s ease-in-out;
  height: 340px;
}

.location-to-reach-place{
  margin-left: -6%;
}

.location-to-reach-place svg:hover{
  cursor: pointer;
}

.popUp-audio-book-inner img {
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: auto;
}


.audio-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 74%;
}

.audio-element {
  width: 100%;
  margin-top: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.div-element {
  font-size: 18px;
  color: #7f8c8d;
  font-family: 'Roboto', sans-serif;
}

@media (max-width:431px){
  .popUp-audio-book {
    background: linear-gradient(135deg, #f8f9fa, #ffffff);
    border-radius: 15px;
    width: 100%;
    padding: 25px;
    padding-right: 0px;
    padding-left: 0px;
    position: relative;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    animation: slideUp 0.3s ease-in-out;
  }
}

@keyframes fadeIn {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

@keyframes slideUp {
  from {
      transform: translateY(100%);
  }
  to {
      transform: translateY(0);
  }
}

.popUp-booking-tour-guide {
  background: linear-gradient(135deg, #f8f9fa, #ffffff);
  border-radius: 15px;
  width: 90%;
  max-width: 600px;
  padding: 25px;
  position: relative;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  animation: slideUp 0.3s ease-in-out;
  height: 540px;
}
.pickup-form h2 {
  font-size: 26px;
  color: #2c3e50;
  margin-bottom: 15px;
  font-family: 'Roboto', sans-serif;
}

.pickup-form label {
  display: block;
  font-size: 16px;
  margin-bottom: 10px;
  font-family: 'Roboto', sans-serif;
  color: #34495e;
}

.pickup-form input[type="text"],
.pickup-form input[type="date"],
.pickup-form input[type="time"],
.pickup-form input[type="number"] {
  width: calc(100% - 20px);
  padding: 8px 10px;
  margin-top: 5px;
  margin-bottom: 15px;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  font-size: 14px;
  color: #2c3e50;
  font-family: 'Roboto', sans-serif;
}

.select-option-given-to-user{
  background: linear-gradient(135deg, #f8f9fa, #ffffff);
  border-radius: 15px;
  width: 90%;
  max-width: 400px;
  padding: 25px;
  position: relative;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  animation: slideUp 0.3s ease-in-out;
  height: 340px;
}

.option-given{
  background-color: silver;
  height: 150px;
  width: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid grey;
  border-radius: 10px;
}

.image-section-of-option-given{
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: white;
  height: 120px;
  width: 150px;
  background-position: center;
  background-repeat: no-repeat;
}

.option-given:hover {
  border:2px solid #252525;
}


#instant-booking{
  background-image: url('../../public/instant.png');
  background-size: 90%;
}

#reserved-booking{
  background-size: 70%;
  background-image: url('../../public/reserved.png');
}

#fialalize-select-option{
  border: none;
  font-size: 16px;
  background-color: black;
  color: white;
  padding: 10px;
  border-radius: 7px;
}


.pickup-form input[type="text"]:focus,
.pickup-form input[type="date"]:focus,
.pickup-form input[type="time"]:focus,
.pickup-form input[type="number"]:focus {
  border-color: #494949;
  outline: none;
}

.pickup-form button[type="button"] {
  width: 35px;
  height: 35px;
  background-color: grey;
  border: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pickup-form button[type="button"]:hover {
  background-color: #6c757d;
}

.pickup-form h3 {
  font-size: 20px;
  color: #e74c3c;
  font-family: 'Roboto', sans-serif;
  margin-top: 10px;
  margin-bottom: 20px;
}

.pickup-form button[type="submit"] {
  width: 100%;
  padding: 10px 15px;
  background-color: #545454;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  transition: background-color 0.3s ease;
}

.pickup-form button[type="submit"]:hover {
  background-color: #000000;
}

.errorInEmail {
  font-size: 14px;
  color: #e74c3c;
  font-family: 'Roboto', sans-serif;
  margin-top: 10px;
  text-align: center;
}

@keyframes fadeIn {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

@keyframes slideUp {
  from {
      transform: translateY(100%);
  }
  to {
      transform: translateY(0);
  }
}

  


.book-tour-price {
  background-color: #f7f7f7;
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.book-tour-operator h2 {
  color: #6c35ca;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

label {
  font-size: 14px;
  color: #333;
}

input[type='number'] {
  width: 90%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 5px;
  font-size: 14px;
}

.submit-btn {
  background-color: #994292;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.submit-btn:hover {
  background-color: #a12892;
}

.error-message {
  color: red;
  margin-top: 10px;
  font-size: 12px;
}
