.termsAndServices{
    display: flex;
    flex-direction: column;
    margin-left: 5%;
    margin-right: 5%;
}

.termsAndServices h1{
    margin-top: 50px;
    margin-bottom: 20px;
    align-self: center;
}
.termsAndServices h3{
    margin-bottom: 0px;
}
.termsAndServices h4{
    margin-bottom: 0px;
}
.termsAndServices h5{
    margin-bottom: 0px;
}

@media (max-width:700px){
    .termsAndServices h1{
        font-size: 25px;
    }
    .termsAndServices h3{
        font-size: 16px;
        margin-bottom: 0px;
    }
    .termsAndServices h4{
        font-size: 15px;
        margin-bottom: 0px;
    }
    .termsAndServices h5{
        font-size: 12px;
        margin-bottom: 0px;
    }
    .termsAndServices p{
        font-size: 12px;
    }
    .termsAndServices ul li{
        font-size: 12px;
    }
    @media (max-width:330px){
        .termsAndServices h1{
            font-size: 20px;
        }
        .termsAndServices h3{
            font-size: 14px;
        }
        .termsAndServices h4{
            font-size: 13px;
        }
        .termsAndServices h5{
            font-size: 11px;
        }
        .termsAndServices p{
            font-size: 10px;
        }
    }
}